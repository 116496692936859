import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    const [opened, setOpened] = useState(null);

    const switchOpened = (num) => {
        if (opened === num) {
            setOpened(null)
        } else {
            setOpened(num);
        }
    }

    return (
        <Layout location={location}>
            <Seo
                title="Közvetítő szolgáltató (tárhely- vagy felhőszolgáltató) vagyok"
                description="A DSA rendelet bevezette a jogellenes tartalom bejelentési lehetőség biztosításának kötelezettségét a közvetítő szolgáltatók részére is. Megelőzhető a DSA szankciója, itt ismertetjük a szabályokat és a lehetőségeket."
                keywords="DSA rendelet, DSA rendeletnek való megfelelés, jogellenes tartalom bejelentése, DSA szankció, közvetítő szolgáltatók jogellenes tartalom bejelentés lehetőségének biztosítási kötelezettsége"

            />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>
                            <p onClick={() => switchOpened(1)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Miért vonatkozik rám ez a szabályozás?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 1 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A közvetítő szolgáltatásokra korábban is különböző szabályok vonatkoztak. Az Európai Unió kiemelten fontosnak tartja az interneten megjelenő jogellenes tartalmak csökkentését, ezért 2024-től egy új, az előzőnél szigorúbb szabályozást vezet be a közvetítő szolgáltatók számára.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(2)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Mitől lesz szigorúbb a 2024-től hatályba lépő szabályozás a korábbinál?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 2 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A korábbi szabályok nem írtak elő kötelező bejelentési mechanizmusokat a közvetítő szolgáltatók számára, valamint az általuk tárolt, hozzáférhetővé tett és továbbított jogellenes tartalmak tekintetében csak szűk körben voltak felelősek.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(3)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Felelős lehetek-e az általam tárolt, továbbított, vagy hozzáférhetővé tett jogellenes tartalmakért?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 3 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Igen, hogyha a jogellenes tartalmat harmadik személyek a bejelentési rendszeren keresztül a tudomására hozták és a kivizsgálási kötelezettségének nem tett eleget.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(4)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Mit takar a bejelentési rendszer?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 4 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Az új szabályozás szerint a közvetítő szolgáltatók olyan ingyenes és elektronikusan hozzáférhető bejelentési rendszert kell, hogy kialakítsanak, amely lehetővé teszi, hogy harmadik személyek bejelentsenek esetleges jogellenes tartalmakat.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(5)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Ki tehet bejelentést?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 5 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Bárki, aki szerint a tartalom jogellenes.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(6)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Milyen kritériumoknak kell a bejelentési rendszernek megfelelnie?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 6 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentési rendszernek meg kell könnyítenie, hogy a bejelentő kellően pontosan, megfelelően indokolt bejelentést tudjon tenni.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(7)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Van-e bármilyen tájékoztatási kötelezettségem a bejelentő felé?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 7 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Igen, amennyiben a bejelentő az elérhetőségeit megadta, úgy a bejelentés kézhezvételét meg kell erősíteni.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(8)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Hogyan kell a bejelentéseket kivizsgálnom?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 8 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Időben, kellő gondossággal és tárgyilagosan eljárva kell a bejelentéseket kivizsgálni és döntést hozni.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(9)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Milyen döntést hozhatok a bejelentéssel érintett tartalommal kapcsolatban?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 9 ? 'auto' : 0, overflow: "hidden" }}>
                                <ul className="list-base">
                                    <li>Amennyiben a vitatott tartalom nem jogellenes és összhangban van a felek között fennálló szerződéssel, úgy nem tesz semmilyen intézkedést a közvetítő szolgáltató.</li>
                                    <li>
                                        Amennyiben viszont a tartalom jogellenes vagy nincs összhangban a felek közötti szerződéssel, úgy tartalommoderálást végez, amely lehet:
                                        <ul className="list-base" >
                                            <li>az ügyfél által nyújtott információk láthatóságának korlátozása, a tartalom eltávolítása, a tartalomhoz való hozzáférés megszüntetése vagy a tartalom hátrasorolása,</li>
                                            <li>a pénzkifizetések felfüggesztése megszüntetése, egyéb korlátozása,</li>
                                            <li>a szolgáltatásnyújtás teljes vagy részleges felfüggesztése, megszüntetése,</li>
                                            <li>az ügyfél fiókjának felfüggesztése vagy megszüntetése.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <p onClick={() => switchOpened(10)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Köteles vagyok indokolni a meghozott döntést?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 10 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Hogyha a közvetítő szolgáltató megállapítja a tartalom jogellenességét, úgy azt köteles indokolni a fent meghatározott kötelező tartalmi elemek beépítésével.

                                </p>
                            </div>
                            <p onClick={() => switchOpened(11)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Mit kell tennem, ha bűncselekmény gyanúját észlelem?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 11 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Amennyiben felmerült bűncselekmény elkövetésének gyanúja, úgy tájékoztatnia kell az illetékes tagállam / tagállamok bűnüldözési vagy igazságügyi hatóságait.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(12)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Mit kell tennem, ha az ügyfél nem ért egyet a döntésemmel?
                                </strong>
                            </p>
                            <div className="mb-2" style={{ height: opened === 12 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A közvetítő szolgáltató köteles a döntést követően legalább 6 hónapig hozzáférést biztosítani egy hatékony belső panaszkezelési rendszerhez, ahol a döntéssel érintett igénybe vevő elektronikus úton, ingyenesen tudja panaszát benyújtani.
                                </p>
                            </div>

                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "kozvetito-gyik" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
