import React, { useContext } from "react";
import { navigate } from "gatsby"
import UserContext from "../context/UserContext";

const RedirecterUnauthenticated = ({ to }) => {
    const isSSR = typeof window === "undefined";

    const { user } = useContext(UserContext);

    if (!user?.id&&!isSSR) {
        navigate(to);
    }
    return <></>;
};

export default RedirecterUnauthenticated;
