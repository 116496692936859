import React, { useContext, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Row, Col } from "@ui/wrapper";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { FormGroup, Input, Textarea } from "@ui/form-elements";
import Button from "@ui/button";
import UserContext from "../../../context/UserContext";
import { StyledNavlink } from "../../menu/main-menu/style";

const LoginForm = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { login } = useContext(UserContext);

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });


    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://back.itlawsolutions.hu/api/auth/login",
            data: data,
        })
            .then((r) => {
                login(r?.data?.access_token);
            })
            .catch((r) => {
                setServerState({ submitting: false, status: { ok: true, msg: "Hiba a megadott adatokban." } })
            });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="email"
                            id="email"
                            placeholder="Email *"
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "email") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "email")}
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="password"
                            id="password"
                            placeholder="Jelszó *"
                            feedbackText={errors?.password?.message}
                            state={
                                hasKey(errors, "password") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "password")}
                            {...register("password", {
                                required: "Password is required",
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Button type="submit" disabled={serverState.submitting}>
                        Bejelentkezés
                    </Button>
                    <StyledNavlink
                        className="nav-link"
                        path="/register"
                        vSpace={2}
                        space={2}
                        color="dark"
                        bottomLine={true}
                    >
                        <span>Még nincs fiókom, regisztrálok.</span>
                    </StyledNavlink>
                    {serverState.status && (
                        <p
                            className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"
                                }`}
                        >
                            {serverState.status.msg}
                        </p>
                    )}
                </Col>
            </Row>
        </form>
    );
};

export default LoginForm;
