import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@styled";
import { theme } from "@styled/theme";
import "@assets/fonts/font-awesome/font-awesome.css";
import "@assets/css/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalStyle } from "@assets/css/main-style";
import ScrollToTop from "@ui/scroll-to-top";
import Transition from "@components/transition";
import axios from "axios";

import UserContext from '../context/UserContext';

const Layout = ({ children, location }) => {
    const isSSR = typeof window === "undefined";

    const [user, setUser] = useState(
        isSSR ? null :
            window?.localStorage?.getItem('user') ? JSON.parse(window?.localStorage?.getItem('user')) : null
    );
    const [cart, setCart] = useState(
        isSSR ? null :
            window?.localStorage?.getItem('cart') ? JSON.parse(window?.localStorage?.getItem('cart')) : null
    );

    const handleSetUser = (hUser) => {
        if (hUser === "") {
            setUser(null);
            if (!isSSR) {
                window.localStorage.removeItem("user");
            }
        } else {
            setUser(hUser);
            if (!isSSR) {
                window.localStorage.setItem("user", JSON.stringify(hUser));
            }
        }
    }

    const handleSetCart = (hCart) => {
        if (hCart === "") {
            setCart(null);
            if (!isSSR) {
                window.localStorage.removeItem("cart");
            }
        } else {
            setCart(hCart);
            if (!isSSR) {
                window.localStorage.setItem("cart", JSON.stringify(hCart));
            }
        }
    }

    const login = (access_token) => {
        axios({
            method: "post",
            url: "https://back.itlawsolutions.hu/api/auth/me",
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        })
            .then((r) => {
                if (r?.data) {
                    handleSetUser({ ...r?.data, access_token: access_token });
                }
            })
            .catch((r) => {
                console.log('err')
            });
    }

    const logout = () => {
        handleSetUser("");
    }

    return (
        <UserContext.Provider value={{ user, login, logout, handleSetCart, cart }}>
            <ThemeProvider theme={theme}>
                <Transition location={location}>
                    <div className="wrapper">
                        <GlobalStyle />
                        {children}
                        <ScrollToTop />
                    </div>
                </Transition>
            </ThemeProvider>
        </UserContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({}),
};

export default Layout;
