import React, { useContext } from "react";
import { navigate } from "gatsby"
import UserContext from "../context/UserContext";

const Redirecter = ({ to }) => {
    const { user } = useContext(UserContext);

    if (user?.id > 0) {
        navigate(to);
    }
    return <></>;
};

export default Redirecter;
