import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const TarhelyMiisezPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo title="Tárhely bejelentés" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>

                            <h3 className="h4 pt-5">1. A SZABÁLYOZÁSSAL ÉRINTETTEK KÖRE, AZAZ KIK A KÖZVETÍTŐ SZOLGÁLTATÓK?</h3>
                            <p className="text-center">
                                <StaticImage
                                    src="../../assets/images/abra/abra_1.png"
                                    alt="Ábra 1."
                                    style={{
                                        maxWidth: '600px',
                                        width: '100%',
                                        margin: '50px 0'
                                    }}
                                />
                            </p>

                            <p>
                                A közvetítő szolgáltató vállalja különböző információk:
                                <ul className="list-base">
                                    <li>továbbítását, </li>
                                    <li>hozzáférhetőségének biztosítását, </li>
                                    <li>tárolását.</li>
                                </ul>
                            </p>

                            <h3 className="h4 pt-5">
                                2.	MILYEN SZABÁLYOK VONATKOZNAK A KÖZVETÍTŐ SZOLGÁLTATÓKRA?
                            </h3>

                            <p>
                                2024 februárjától új korszak köszönt be a közvetítő szolgáltatók (mint például: webhosting szolgáltatók, felhőszolgáltatók) működésében, ugyanis a régi szabályozást felváltja egy új, az Európai Unió által meghatározott szabályrendszer, amely segíti az interneten megjelenő jogellenes tartalmakkal szembeni küzdelmet.
                            </p>

                            <p>
                                A közvetítő szolgáltatásokkal kapcsolatos korábbi szabályokat az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény (röviden: Eker. tv.) tartalmazta. Ennek a közvetítő szolgáltatások és azok felelősségére vonatkozó szabályait váltja fel 2024. év elejétől a digitális szolgáltatások egységes piacáról és a 2000/31/EK irányelv módosításáról szóló Európai Parlament és Tanács (EU) 2022/2065 rendelete (röviden: DSA), amely sokkal szigorúbb felelősségi szabályokat és azok megsértése esetén egy szankciórendszert vezet be.
                            </p>

                            <p>
                                <strong>
                                    Mik a lényeges különbségek a közvetítő szolgáltatókat érintő régi és új szabályok között:
                                </strong>
                            </p>
                            <table className="table table-bordered text-center">
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50%' }}></td>
                                        <td style={{ width: '25%' }}>Régi szabályozás</td>
                                        <td style={{ width: '25%' }}>Új szabályozás</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>A megjelenített tartalmakért felelős a közvetítő szolgáltató? </td>
                                        <td>Csak szűk körben</td>
                                        <td>Igen, amennyiben a tudomására jut, hogy jogellenes a tartalom</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>Kötelező bejelentési rendszer működtetése annak érdekében, hogy harmadik személyek bejelentést tudjanak tenni? </td>
                                        <td> <i className="fa fa-times"></i></td>
                                        <td> <i className="fa fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>Kell tájékoztatni a hatóságokat bűncselekmény gyanújának észlelése esetén? </td>
                                        <td> <i className="fa fa-times"></i></td>
                                        <td> <i className="fa fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>Szükséges indokolni a tartalommoderálással kapcsolatos döntéseket? </td>
                                        <td> <i className="fa fa-times"></i></td>
                                        <td> <i className="fa fa-check"></i></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>Érheti a közvetítő szolgáltatót hátrány, ha a tartalommoderálási kötelezettségeket nem teljesíti?</td>
                                        <td> <i className="fa fa-times"></i></td>
                                        <td> <i className="fa fa-check"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                Ezek alapján 2024-től a közvetítő szolgáltatóknak:
                            </p>
                            <ul className="list-base">
                                <li>biztosítaniuk kell egy elektronikus bejelentési rendszert, ahol harmadik személyek tehetnek bejelentést a megjelenített, tárolt tartalmakkal kapcsolatban,</li>
                                <li>a bejelentett tartalmakat meg kell vizsgálniuk, és azok jogellenességével kapcsolatban döntést kell hozniuk,</li>
                                <li>a jogellenes tartalomról hozott döntést indokolniuk kell,</li>
                                <li>bűncselekmény gyanúja esetén az illetékes hatóságot tájékoztatniuk kell,</li>
                                <li>biztosítaniuk kell a döntéssel összefüggő belső panaszkezelési rendszert,</li>
                                <li>mindezen új szabályrendszerhez kell igazítaniuk a korábbi és a jövőbeli ügyfelekkel kötött / kötendő szerződések feltételeit.</li>
                            </ul>

                            <h3 className="h4 pt-5">
                                3. A KÖZVETÍTŐ SZOLGÁLTATÓ, AZ ÜGYFÉL ÉS A HARMADIK SZEMÉLYEK KAPCSOLATAI.
                            </h3>

                            <p>
                                <strong>
                                    A közvetítő szolgáltatók  és ügyfeleik  közötti viszonyok a régi szabályok szerint:
                                </strong>
                            </p>
                            <p className="center-text">
                                <StaticImage
                                    src="../../assets/images/abra/abra_2.png"
                                    alt="Ábra 2."
                                    style={{
                                        maxWidth: '1000px',
                                        width: '100%',
                                        margin: '50px 0'
                                    }}
                                />
                            </p>


                            <p>
                                <strong>
                                    A közvetítő szolgáltatók, ügyfeleik, valamint harmadik személyek  kapcsolata az új szabályok szerint:
                                </strong>
                            </p>

                            <p className="center-text">
                                <StaticImage
                                    src="../../assets/images/abra/abra_3.png"
                                    alt="Ábra 3."
                                    style={{
                                        maxWidth: '1000px',
                                        width: '100%',
                                        margin: '50px 0'
                                    }}
                                />
                            </p>

                            <h3 className="h4 pt-5">
                                4. MIBEN MÁS AZ ÁLTALUNK KÍNÁLT BEJELENTÉSI RENDSZER?
                            </h3>

                            <p>
                                A bejelentési rendszernek az új szabályozás szerint:
                            </p>
                            <ul className="list-base">
                                <li>mindenki számára elérhetőnek kell lennie,</li>
                                <li>elektronikus úton történő bejelentéseket kell lehetővé tennie,</li>
                                <li>meg kell könnyítenie, hogy harmadik személy pontos és indokolt bejelentést tudjon tenni.</li>
                            </ul>

                            <p>
                                Így az általunk kínált bejelentési rendszer:
                            </p>
                            <ul className="list-base">
                                <li>lehetővé teszi, hogy a bejelentő kellően részletesen elmagyarázhassa, hogy szerinte miért jogellenes a bejelentett tartalom,</li>
                                <li>lehetővé teszi, hogy a megjelölt tartalom helye egyértelműen beazonosítható legyen (ezt akár URL cím megadásával, akár további információk, mint a tartalom típusa és a tárhelyszolgáltatás típusának megadásával)</li>
                                <li>lehetővé teszi, hogy a bejelentő a nevét és az e-mail címét megadhassa,</li>
                                <li>tartalmaz egy nyilatkozatot, amelyben a bejelentő megerősíti, hogy jóhiszeműen járt el, valamint az általa megadott információk pontosak és hiánytalanok.</li>
                            </ul>

                            <p>
                                <strong>
                                    MIT TESZÜNK, HA A BEJELENTETT TARTALOM VALÓBAN JOGELLENES?
                                </strong>
                            </p>

                            <p style={{ textDecoration: 'underline' }}>
                                INDOKOLÁSI KÖTELEZETTSÉGÜNK VAN (NEKÜNK A SZOLGÁLTATÓ FELÉ, A SZOLGÁLTATÓNAK A SAJÁT ÜGYFELE FELÉ):
                            </p>
                            <p>
                                <strong>
                                    Amennyiben közvetítő szolgáltató elfogadja a javaslatunkat a tartalommoderálással kapcsolatban és végrehajtja azt (az általa tárolt vagy hozzáférhetővé tett információval kapcsolatban), úgy javaslatunk alapján erről egyértelmű és konkrét indokolást szükséges adnia ügyfelének.
                                </strong>
                            </p>
                            <p>
                                Az indokolási kötelezettséggel kapcsolatos feltételek:
                            </p>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="p-2 mx-1 text-center" style={{ backgroundColor: "#086AD8", color: "#fff" }}>
                                        Amit tehet a közvetítő szolgáltató a jogellenes tartalom moderálása érdekében:
                                    </div>
                                    <div className="p-2 mx-1" style={{ border: "1px solid #086AD8" }}>
                                        <ul className="list-base" >
                                            <li>Információk láthatóságának korlátozása</li>
                                            <li>Pénzkifizetések felfüggesztése, megszüntetése és egyéb korlátozása,</li>
                                            <li>Szolgáltatásnyújtás teljes vagy részleges felfüggesztése vagy megszüntetése,</li>
                                            <li>Az ügyfél fiókjának megszüntetése</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="p-2 mx-1 text-center" style={{ backgroundColor: "#086AD8", color: "#fff" }}>
                                        Indokolás kötelező tartalmi elemei:
                                    </div>
                                    <div className="p-2 mx-1" style={{ border: "1px solid #086AD8" }}>
                                        <ul className="list-base" >
                                            <li>Tájékoztatás a kiszabott szankciókról</li>
                                            <li>Tájékoztatás arról, hogy a szolgáltató milyen tényeket vett figyelembe a döntéshozatal során</li>
                                            <li>Tájékoztatás arról, hogy használt-e automatizált döntéshozatalt</li>
                                            <li>A jogellenesség magyarázata (jogalap)</li>
                                            <li>Tájékoztatás arról, hogy a tartalom a szolgáltató és az ügyfél közötti szerződésbe ütközik </li>
                                            <li>Tájékoztatás az ügyfél által igénybe vehető jogorvoslati lehetőségekről</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <p>
                                A fentieket a szolgáltató cégünk javaslata alapján, a szolgáltató ügyfele felé egyértelműen és világosan kommunikálva hajtja végre.
                            </p>
                            <p>
                                <strong>
                                    MIT TESZÜNK, HA BŰNCSELEKMÉNY GYANÚJA MERÜL FEL A TÁROLT, TOVÁBBÍTOTT VAGY HOZZÁFÉRHETŐVÉ TETT TARTALOMMAL KAPCSOLATBAN?
                                </strong>
                            </p>
                            <p style={{ textDecoration: 'underline' }}>
                                BŰNCSELEKMÉNY GYANÚJÁNAK BEJELENTÉSE:
                            </p>


                            <p>
                                A közvetítő szolgáltató cégünk bejelentési rendszerén keresztül szerezhet tudomást a jogellenes tartalommal megvalósított bűncselekményről.
                            </p>
                            <p>
                                Amennyiben a közvetítő szolgáltató tudomást szerez arról, hogy az általa tárolt, továbbított vagy hozzáférhetővé tett információ valamilyen bűncselekmény elkövetéséhez kapcsolódik, úgy köteles erről az érintett tagállam hatóságait tájékoztatni és a releváns információkat átadni.
                            </p>
                            <p>
                                Természetesen ennek érdekében az általunk továbbított javaslat tartalmazza a hatóságok irányában a bűncselekmény bejelentését, illetve a bejelentést ebben az esetben mi magunk is megtesszük a szolgáltató választása szerint.
                            </p>

                            <h3 className="h4 pt-5">
                                5. MI TÖRTÉNIK, HA EZEKET A SZABÁLYOKAT A KÖZVETÍTŐ SZOLGÁLTATÓ NEM TARTJA BE?
                            </h3>

                            <p>
                                A közvetítő szolgáltatásokra vonatkozó szabályok talán legfontosabb újítása az, hogy amennyiben teljesítik a fent kifejtett kötelezettségeket, úgy a felügyeletet ellátó szerv (Nemzeti Média és Hírközlési Hatóság) szankciókat szabhat ki.
                            </p>
                            <p>
                                Az alkalmazható szankciók legmagasabb formája a
                            </p>
                            <p>
                                <strong>PÉNZBÍRSÁG,</strong> melynek <strong>maximális összege</strong> az érintett közvetítő szolgáltató <strong>előző pénzügyi évi globális éves forgalmának 6%-a.</strong> A pénzbírság, vagy más a tagállam által meghatározott szankciók kiszabása a digitális szolgáltatási koordinátorok hatáskörébe tartozik.
                            </p>
                            <p>
                                <strong>
                                    MI TÖRTÉNIK, HA AZ ÜGYFÉL NEM FOGADJA EL A KÖZVETÍTŐ SZOLGÁLTATÓ DÖNTÉSÉT?
                                </strong>
                            </p>

                            <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Belső panaszkezelési rendszer (ezt biztosítjuk szolgáltató ügyfeleinknek):
                            </p>

                            <ul className="list-base">
                                <li>A közvetítő szolgáltató kötelessége, hogy a döntést követő 6 hónapig elérhetővé tesz az ügyfele számára egy belső panaszkezelési rendszert. Ez elérhető, bármelyik termékünk választása esetén.</li>
                                <li>A belső panaszkezelési rendszer lehetővé teszi, hogy a döntéssel érintett személy (azaz az ügyfél) elektronikus úton, ingyenesen tegyen panaszt a reá vonatkozó döntéssel szemben. A rendszernek könnyen hozzáférhetőnek, felhasználóbarátnak kell lennie annak érdekében, hogy lehetővé tegye a kellően pontos és megfelelően indokolt panaszok fogadását. </li>
                                <li>A közvetítő szolgáltató nevében, a közvetítő szolgáltatóval együttműködve időben, kellő gondossággal, megkülönböztetésmentesen és pártatlanul bíráljuk el a panaszt. A döntést a szolgáltató hozza meg javaslatunk alapján.</li>
                                <li>A meghozott döntésnek két kimenetele lehet: </li>
                                <ul>
                                    <li>[1]	A korábban moderált tartalom nem volt jogellenes, ezért intézkedik a korábbi döntésének visszavonásáról, valamint, ha ezzel kárt okozott kártérítésre is kötelezhető. </li>
                                    <li>[2]	A korábban moderált tartalom jogellenes volt, ezért fenntartja a döntését. </li>
                                </ul>

                            </ul>



                            <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Peren kívüli vitarendezés:
                            </p>
                            <ul className="list-base">
                                <li>Amennyiben a belső panaszkezelési rendszer segítségével nem sikerül feloldani a jogvitát, úgy a közvetítő szolgáltató döntésével érintettek jogosultak kiválasztani egy peren kívüli vitarendezési testületet.</li>
                                <li>A peren kívüli vitarendezés nem érinti a bírói út igénybevételének lehetőségét.</li>
                                <li>A közvetítő szolgáltató abban az esetben tagadhatja meg a peren kívüli vitarendezési testülettel való együttműködést, amennyiben az érintett jogellenes tartalommal kapcsolatban már született döntés.</li>
                                <li>A peren kívüli vitarendezési testület az érintett tagállam digitális szolgáltatási koordinátorának (NMHH elnöke) tanúsításával, legfeljebb ötéves, megújítható időszakban működik.</li>
                            </ul>

                            <p style={{ fontWeight: "bold", textDecoration: "underline" }}>
                                Peres út:
                            </p>
                            <ul className="list-base">
                                <li>A korábbi szabályozáshoz hasonlóan az ügyfélnek továbbra is lehetősége van arra, hogy bírói utat vegyen igénybe a döntéssel szemben, ám ez - lehetőség szerint - a fent említett jogorvoslatok biztosításával elkerülhető lesz.</li>
                            </ul>
                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query TarhelyMiisezPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "tarhely-miisez" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

TarhelyMiisezPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default TarhelyMiisezPage;
