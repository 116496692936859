import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    const [opened, setOpened] = useState(null);

    const switchOpened = (num) => {
        if (opened === num) {
            setOpened(null)
        } else {
            setOpened(num);
        }
    }

    return (
        <Layout location={location}>
            <Seo
                title="Cég (kötelezett) vagyok – cégek által feltett gyakori kérdések"
                description="A visszaélés-bejelentési lehetőség biztosításának úgy is eleget tehet a kötelezett, hogy letölti az IT Law Solutions által fejlesztett egyedi szoftvert és megrendeli bejelentővédelmi szolgáltatásunkat. A panasztörvény alapján erre kötelezett vállalkozások részére az itt olvasható információk is segítenek tisztán látni."
                keywords="visszaélés-bejelentés, panasztörvény, bejelentővédelem"

            />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>
                            <p>
                                Amennyiben Ön a Panasztörvény alapján bejelentések fogadására és kivizsgálására köteles, vagy ilyen rendszert kíván bevezetni vállalkozásában, az alábbi tudnivalók hasznos támpontként szolgálhatnak a megfelelő szolgáltatás kiválasztásában:
                            </p>
                            <p onClick={() => switchOpened(1)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                1.	Kikre nézve állapít meg kötelezettséget a Panasztörvény?
                            </p>
                            <div className="mb-2" style={{ height: opened === 1 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A panaszokról, a közérdekű bejelentésekről, valamint visszaélések bejelentésével összefüggő szabályokról szóló 2023. évi XXV. törvény az állami, valamint helyi önkormányzati szervekre, emellett a legalább 50 főt foglalkoztató vállalkozásoknál írja elő kötelezően panaszok, valamint közérdekű bejelentések elintézését.
                                </p>
                            </div>

                            <p onClick={() => switchOpened(2)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                2.	Mikortól lép hatályba a szabályozás?
                            </p>
                            <div className="mb-2" style={{ height: opened === 2 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A szabályozás 2023. július 24.-én lépett hatályba a 250 főnél több személyt foglalkoztató vállalkozások tekintetében. Az 50-249 főt foglalkoztató vállalkozások tekintetében a törvény 2023. december 17-én lép hatályba.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(3)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                2.	Hogyan lehet működtetni belső visszaélés-bejelentési rendszert?
                            </p>
                            <div className="mb-2" style={{ height: opened === 3 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A panaszbejelentés a törvény alapján írásban vagy szóban történhet meg. Erre tekintettel a foglalkoztató fenntarthat belső visszaélés-bejelentési rendszert, valamint ezt külső közreműködők részére is kiszervezheti.
                                </p>
                                <p>
                                    Versenytársainktól eltérően cégünk egy olyan kompakt megoldást biztosít, amely magában foglalja a belső visszaélés-bejelentési rendszer üzemeltetését, a bejelentővédelmi kötelezettségek teljesítését, valamint a bejelentések kivizsgálását bejelentővédelmi ügyvéd bevonásával. Az általunk kialakított rendszer, mint egy automatikusan beépülő modul ügyfelünk saját weboldaláról közvetlenül válik elérhetővé a bejelentők számára, emellett pedig biztosítjuk a telefonos bejelentés lehetőségét az oldalon feltüntetett telefonszámon.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(4)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                3.	Ki bízható meg a belső visszaélés-bejelentési rendszer működtetésével?
                            </p>
                            <div className="mb-2" style={{ height: opened === 4 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A belső visszaélés-bejelentési rendszer működtetése történhet a foglalkoztató által, amennyiben van olyan szerv / erre kijelölt személy, aki a bejelentések elintézését pártatlanul tudja elvégezni. Tekintettel arra, hogy ezen feladat elvégzése belső szervezetbe történő integrálással előre nem kiszámítható költségekkel járna, így a törvény lehetővé teszi ezen feladat kiszervezését külső szerv vagy bejelentővédelmi ügyvéd részére.
                                </p>
                                <p>
                                    Cégünk nem csupán a belső visszaélés-bejelentési rendszer üzemeltetését és biztosítását ajánlja, hanem a bejelentés megtételétől az ügy lezárásáig részt vesz a törvényi kötelezettségek teljesítésében. Így nem csupán a bejelentési rendszert, hanem a vizsgálat lefolytatását, valamint a bejelentővédelmi kötelezettségeket is a szerződés ideje alatt ellátjuk.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(5)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                4.	Mennyi idő alatt szükséges kivizsgálni a bejelentéseket?
                            </p>
                            <div className="mb-2" style={{ height: opened === 5 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentés kivizsgálására - főszabály szerint - annak beérkezésétől számított 30 nap áll rendelkezésre. Abban az esetben, ha ez a 30 nap nem lenne elegendő a vizsgálat lefolytatására, a bejelentő írásos tájékoztatása mellett lehetőség van annak meghosszabbítására. A határidő így sem terjeszkedhet a beérkezéstől számított 3 hónapon túlra.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(6)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                5.	Kik tehetnek bejelentést?
                            </p>
                            <div className="mb-2" style={{ height: opened === 6 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Bejelentést tehet:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>a vállalkozás által bármilyen jogviszonyban foglalkoztatott személy (tehát nem csupán a munkavállaló, hanem más foglalkoztatási jogviszonyban álló személy is például megbízott, alvállalkozó);</li>
                                        <li>az a személy, aki korábban jogviszonyban állt a vállalkozással (volt munkavállaló és olyan foglalkoztatott, akivel a jogviszony már megszűnt);</li>
                                        <li>az a személy, aki vállalkozással jogviszony létesítésére vonatkozó eljárást kezdett (például, akit állásinterjú keretei között meghallgattak);</li>
                                        <li>egyéni vállalkozó, egyéni cég, illetve az, aki más szerződéses kapcsolatban áll a vállalkozással (például beszállító, alvállalkozó);</li>
                                        <li>tulajdonosi részesedéssel rendelkező személy, az ügyvezető, ügyviteli, illetve felügyelő testülethez tartozó személy (az is, aki nem ügyvezető tag);</li>
                                        <li>aki szerződéses kapcsolat létesítésére vonatkozó eljárást megkezdett, szerződéses kapcsolatban álló vagy állt vállalkozó, alvállalkozó, beszállító vagy megbízott felügyelete és irányítása alatt álló személy;</li>
                                        <li>vállalkozásnál egyéb tevékenységet végző személy (például önkéntes vagy gyakornok).</li>
                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(7)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                6.	Mire tekintettel tehető bejelentés?
                            </p>
                            <div className="mb-2" style={{ height: opened === 7 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentés tárgya lehet valamilyen jogellenes vagy jogellenesnek vélt cselekmény, mulasztás, valamint érdeksérelem egyaránt. Nem szükségszerű, hogy a bejelentő a bejelentett körülményt a munkavégzése, feladatellátása során észlelje, azt észlelheti azon kívül a vállalkozással összefüggésben.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(8)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                7.	A bejelentés részben vagy egészbeni kivizsgálására megbízható külső fél?
                            </p>
                            <div className="mb-2" style={{ height: opened === 8 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Belső visszaélés-bejelentési rendszer fenntartása, valamint a bejelentések kivizsgálása a törvényben meghatározott vállalkozások számára kötelező. Az 50 és 249 fő közötti foglalkoztatottal rendelkező vállalkozások ennek a feltételnek egyénileg vagy egymással társulva is eleget tehetnek.
                                </p>
                                <p>
                                    A panaszkezelés történhet a vállalkozáson belül kinevezett személlyel / létrehozott szervvel, amely személy / szerv pártatlan eljárása biztosítja a bejelentések hatékony kivizsgálását. Tekintettel azonban arra, hogy kisebb vállalkozások számára ezt előre meg nem határozható mértékű kiadásokat eredményezne, a törvény lehetővé teszi külső szerv / bejelentővédelmi ügyvéd megbízását ennek ellátása érdekében. Ez azt jelenti, hogy a külső szerv / bejelentővédelmi ügyvéd nem csupán fogadja a bejelentéseket, vagy üzemelteti a belső visszaélés-bejelentési rendszert, hanem azokat részben vagy egészben ki is vizsgálhatja, amely vizsgálat eredményéről mind a bejelentőt, mind a vállalkozást írásban tájékoztatja.
                                </p>
                                <p>
                                    Cégünk a belső visszaélés-bejelentési rendszer működtetésén túl biztosítja ügyfeleinek a bejelentések teljeskörű kivizsgálását oly módon, hogy a vizsgálat lefolytatására bejelentővédelmi ügyvédet vesz igénybe. Ezáltal ügyfeleinknek nem szükséges a vizsgálat lefolytatására külön bejelentővédelmi ügyvédet megbíznia, hiszen cégünk az egész folyamat során közreműködik a törvényben meghatározottak teljesítésében.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(9)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                8.	Milyen szabályok vonatkoznak a bejelentővédelmi ügyvédre?
                            </p>
                            <div className="mb-2" style={{ height: opened === 9 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Bejelentővédelmi ügyvéd is megbízható a bejelentések becsatornázásával, valamint azok részben vagy egészben történő kivizsgálásával, mindennek alapvető feltétele az ügyben való pártatlanság. Emellett a bejelentővédelmi ügyvéd nem állhat a vállalkozással, amellyel megbízási jogviszonyban, munkaviszonyban vagy más munkavégzési kötelezettséggel járó más jogviszonyban áll, vagy állt a szerződés megkötését megelőző 5 évben.
                                </p>
                                <p>
                                    A bejelentővédelmi ügyvéd a tevékenysége keretén belül:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>fogadja a bejelentéseket,</li>
                                        <li>jogi tanácsadást nyújt a bejelentőnek a bejelentéssel kapcsolatban,</li>
                                        <li>kapcsolatot tart a bejelentővel: ennek keretén belül tájékoztatást adhat és felvilágosítást kérhet,</li>
                                        <li>közreműködhet a vizsgálat lefolytatásában,</li>
                                        <li>a bejelentőt kérés esetén tájékoztatja a bejelentéssel kapcsolatos eseményekről, a vizsgálat eredményéről, megtett intézkedésekről, valamint a vizsgálat lefolytatásának elutasításáról.</li>
                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(10)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                9.	Mely bejelentőt illeti meg a bejelentővédelem?
                            </p>
                            <div className="mb-2" style={{ height: opened === 10 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A törvény előírja, hogy a bejelentő védelemben részesül abban az esetben, ha a bejelentett információk, körülmények a törvény mellékleteiben felsorolt európai uniós aktusok, valamint az azok végrehajtását célzó magyar jogszabályok hatálya alá tartoznak, vagy a bejelentő alapos okkal feltételezte, hogy az információk ilyen szabály hatálya alátartoznak. Ilyen szabályok körébe sorolja a törvény a közbeszerzési eljárásokat, pénzügyi szolgáltatásokat, valamint a fogyasztóvédelmi és élelmiszerbiztonsági szabályokat.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(11)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                10.	Mikor nem illeti meg védelem a bejelentőt?
                            </p>
                            <div className="mb-2" style={{ height: opened === 11 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A törvényben meghatározott bejelentővédelem nem illeti meg a bejelentőt, ha bejelentő:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>a bejelentéssel összefüggésben a minősített adatok védelmére vonatkozó szabályokat megszegi,</li>
                                        <li>orvosi titokra, ügyvédi titokra vonatkozó szabályokat megszegi,</li>
                                        <li>egyházi személy vagy vallási vezetővel kapcsolatos titoktartási kötelezettségének nem tesz eleget,</li>
                                        <li>bírói ítélkezéssel kapcsolatban törvény által védett titok védelmére vonatkozó szabályokat megszegi,</li>
                                        <li>egyes rendvédelmi szervek, a Katonai Nemzetbiztonsági Szolgálat, valamint a Nemzeti Adó- és Vámhivatal állományának tagjaként ezen szervekre irányadó szabályokat megszegi.</li>
                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(12)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                11.	Milyen bejelentővédelmi eszközöket határoz meg a törvény?
                            </p>
                            <div className="mb-2" style={{ height: opened === 12 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentőt megilleti a védelem minden olyan hátrányos intézkedéssel szemben, amelyet a bejelentésre tekintettel alkalmaz ellene a foglalkoztató, ideértve többek között a csoportos létszámcsökkentést, felmondást, lefokozást. Emellett a bejelentés nyilvánosságra hozatalával kapcsolatban is több szabályt határoz meg a törvény. Ezek a bejelentővédelmi eszközök abban az esetben védik a bejelentőt, amennyiben a bejelentés a Törvény mellékleteiben meghatározott európai uniós jogi aktusok, vagy azok végrehajtására szolgáló magyar jogszabályok hatálya alá tartozik. Ilyen területként kerül megjelölésre többek között a környezetvédelem, közbeszerzési eljárások, pénzügyi szolgáltatások.
                                </p>
                                <p>
                                    Cégünk nem csupán a bejelentések kivizsgálását, valamint a bejelentési rendszer üzemeltetését látja el, hanem egyúttal azt is vizsgálja az ügy elintézése során, hogy a bejelentőt megilleti-e a törvényben meghatározott védelem.

                                </p>
                            </div>

                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout >
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "ceg-gyik" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
