import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import DashboardSidebar from "./DashboardSidebar";
import UserContext from "../context/UserContext";


const DashboardElement = ({ location, data }) => {

    const [tickets, setTickets] = useState(null);
    const { user } = useContext(UserContext);

    useEffect(() => {
        axios({
            method: "get",
            url: 'https://back.itlawsolutions.hu/api/tickets',
            headers: {
                Authorization: `Bearer ${user?.access_token}`
            },
        })
            .then((r) => {
                setTickets(r?.data)
            })
            .catch((r) => {
                setTickets(null);
            });
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <DashboardSidebar />
                </div>
                <div className="col-md-9">

                    <table className="table table-bordered">
                        <tr>
                            <th>Státusz</th>
                            <th>Bejelentés időpontja</th>
                            <th>Oldal</th>
                        </tr>
                        {tickets?.map((s, skey) => (<tr>
                            <td>{s?.status}</td>
                            <td>{s?.created_at}</td>
                            <td>{s?.site?.name} ({s?.site?.url})</td>
                        </tr>))}
                    </table>
                </div>
            </div>

        </>
    );
};


export default DashboardElement;
