import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Counter from "@components/counter/layout-01";
import { ItemType } from "@utils/types";
import { StyledSection } from "./style";
import { StyledNavlink } from "../../../components/menu/main-menu/style";

const FunFactArea = ({ data }) => {
    return (
        <StyledSection>
            <Container>
                <Row>
                    {data?.items?.map((item, i) => (
                        <Col md={12} sm={12} key={`fun-fact-${i}`}>

                            <StyledNavlink
                                className="nav-link"
                                path="/tarhely-szolgaltatasunk"
                                vSpace={1}
                                space={1}
                                color="white"
                                bottomLine={true}
                            >
                                <Counter
                                    mb="30px"
                                    layout={2}
                                    text={item.title}
                                    countTo={item.count}
                                />

                            </StyledNavlink>


                        </Col>
                    ))}
                </Row>
            </Container >
        </StyledSection >
    );
};

FunFactArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default FunFactArea;
