import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    const [opened, setOpened] = useState(null);

    const switchOpened = (num) => {
        if (opened === num) {
            setOpened(null)
        } else {
            setOpened(num);
        }
    }

    return (
        <Layout location={location}>
            <Seo title="Bejelentő vagyok" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>

                            <p onClick={() => switchOpened(1)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Mivel kapcsolatban tehetek bejelentést?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 1 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    A közvetítő szolgáltató által tárolt, továbbított és hozzáférhetővé tett jogellenesnek vélt tartalommal kapcsolatban.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(2)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Hogyan tehet bejelentést jogellenes tartalom észlelése esetén?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 2 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Néhány információ megadásával egy – a közvetítő szolgáltató által üzemeltetett - elektronikus bejelentési rendszeren keresztül bejelentést tehet.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(3)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Kapok bármilyen visszajelzést a bejelentésemmel kapcsolatban?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 3 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Amennyiben az elektronikus elérhetőségét a bejelentéskor megadta, úgy a közvetítő szolgáltató tájékoztatni fogja a bejelentés beérkezéséről.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(4)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Miért tegyek ilyen bejelentéseket?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 4 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Az új szabályozás lehetővé teszi, hogy Ön is fellépjen az interneten megjelenő jogellenes tartalmakkal szemben. Mindezen bejelentési rendszer biztosítja, hogy akár közvetlenül a közvetítő szolgáltatónak, például tárhelyszolgáltatónak is jelezze adott tartalom jogellenességét.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(5)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Mi történik, ha az általam tett bejelentésről megállapítják, hogy jogellenes volt?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 5 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Abban az esetben a közvetítő szolgáltató különböző intézkedéseket tesz annak érdekében, hogy a jogellenes tartalom hozzáférését korlátozza.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(6)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Mi történik, ha nem távolítja el a jogellenes tartalmat?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 6 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Abban az esetben a jogszabályban erre feljogosított szerv pénzbírsággal és egyéb szankciókkal sújthatja.
                                </li>
                            </ul>
                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "tarhely-bejelento-gyik" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
