import React, { useContext } from "react";
import { normalizedData } from "@utils";
import UserContext from "../context/UserContext";
import { StyledNavitem, StyledNavlink } from "../components/menu/main-menu/style";


const DashboardSidebar = ({ location, data }) => {
    const { user, logout } = useContext(UserContext);

    return (
        <>
            {user?.id && <>
                <StyledNavitem
                    className="nav-item"
                    space={1}
                    alignment="left"
                >
                    <StyledNavlink
                        className="nav-link"
                        path="/dashboard"
                        vSpace={1}
                        color="dark"
                        bottomLine={true}
                    >
                        <span>Ügyeim</span>
                    </StyledNavlink>
                </StyledNavitem>
                <StyledNavitem
                    className="nav-item"
                    space={1}
                    alignment="left"
                >
                    <StyledNavlink
                        className="nav-link"
                        path="/subscription"
                        vSpace={1}
                        color="dark"
                        bottomLine={true}
                    >
                        <span>Előfizetéseim</span>
                    </StyledNavlink>
                </StyledNavitem>
                { /* <StyledNavitem
                    className="nav-item"
                    space={1}
                    alignment="left"
                >
                    <StyledNavlink
                        className="nav-link"
                        path="/dashboard"
                        vSpace={1}
                        color="dark"
                        bottomLine={true}
                    >
                        <span>Profil adatok</span>
                    </StyledNavlink>
            </StyledNavitem> */ }
            </>}
        </>
    );
};


export default DashboardSidebar;
