import React, { useContext, useEffect, useState } from "react";
import UserContext from "../context/UserContext";
import { StyledNavlink } from "../components/menu/main-menu/style";
import CartForm from "../components/forms/cart-form";


const CartElement = ({ location, data }) => {

    const { user, cart, handleSetCart } = useContext(UserContext);

    if (!cart?.product) {
        return <>
            <h3 className="h4">
                Nincs kiválasztva csomag.
            </h3>
        </>
    } else if (cart?.product === "SUCCESS") {
        return <>
            <h3 className="h4">
                Sikeres megrendelés. E-mailben értesítettük a további teendőkről.
            </h3>
            <p>
                <StyledNavlink
                    className="nav-link"
                    path="#"
                    onClick={() => handleSetCart("")}
                    vSpace={2}
                    color="dark"
                    bottomLine={true}
                >
                    <span>Új csomag vásárlása.</span>
                </StyledNavlink>
            </p>
        </>
    }


    return (
        <>

            <h3 className="h4">
                Kiválasztott csomag: {cart?.product}
            </h3>


            {user?.id ?
                <>
                    <CartForm />
                </>
                : <>
                    <p>
                        A csomag megvásárlásához először jelentkezzen be.
                    </p>
                    <p>
                        <StyledNavlink
                            className="nav-link"
                            path="/login"
                            vSpace={2}
                            color="dark"
                            bottomLine={true}
                        >
                            <span>Bejelentkezés</span>
                        </StyledNavlink>
                    </p>
                </>}


        </>
    );
};


export default CartElement;
