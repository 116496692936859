import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import DashboardSidebar from "./DashboardSidebar";
import UserContext from "../context/UserContext";


const SubscriptionElement = ({ location, data }) => {

    const [subscriptions, setSubscriptions] = useState(null);
    const { user } = useContext(UserContext);

    useEffect(() => {
        axios({
            method: "get",
            url: 'https://back.itlawsolutions.hu/api/subscriptions',
            headers: {
                Authorization: `Bearer ${user?.access_token}`
            },
        })
            .then((r) => {
                setSubscriptions(r?.data)
            })
            .catch((r) => {
                setSubscriptions(null);
            });
    }, [])

    const saveAllowedUrls = (e, s) => {
        axios({
            method: "put",
            url: `https://back.itlawsolutions.hu/api/subscriptions/${s?.id}`,
            data: {
                ...s,
                allowed_urls: e?.target?.value ?? ""
            },
            headers: {
                Authorization: `Bearer ${user?.access_token}`
            }
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <DashboardSidebar />
                </div>
                <div className="col-md-9">

                    <table style={{ fontSize: '14px' }} className="table table-bordered">
                        <tr>
                            <th>Oldal</th>
                            <th>Aktív</th>
                            <th>Érvényeség</th>
                            <th>
                                Előfizetés
                                <br />
                                típusa
                            </th>
                            <th>
                                Engedélyezett oldalak
                                <br />(vesszővel elválasztva)
                            </th>
                            <th>Beágyazás</th>
                        </tr>
                        {subscriptions?.map((s, skey) => (<tr>
                            <td>{s?.name} ({s?.url})</td>
                            <td>{s?.active ? <i style={{ color: 'green' }} className="fa fa-check icon"></i> : <i style={{ color: 'red' }} className="fa fa-times icon"></i>}</td>
                            <td>{s?.valid_until}</td>
                            <td>{s?.subscription_type}</td>
                            <td>
                                <textarea style={{ border: '1px solid #aaa' }} rows={1} onChange={(e) => saveAllowedUrls(e, s)}>
                                    {s?.allowed_urls}
                                </textarea>
                            </td>
                            <td style={{ width: '25%' }}>
                                <code style={{ fontSize: '10px', lineHeight: '12px', display: 'inline-block' }}>
                                    {`<iframe src="https://back.itlawsolutions.hu/iframe/${s?.script_code}" width="100%" height="500px" />`}
                                </code>
                            </td>
                        </tr>))}
                    </table>
                </div>
            </div>

        </>
    );
};


export default SubscriptionElement;
