import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    const [opened, setOpened] = useState(null);

    const switchOpened = (num) => {
        if (opened === num) {
            setOpened(null)
        } else {
            setOpened(num);
        }
    }

    return (
        <Layout location={location}>
            <Seo title="Tárhely vagy felhőszolgáltató ügyfele vagyok" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>

                            <p onClick={() => switchOpened(1)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Milyen tartalommal kapcsolatban tehetnek bejelentést?
                                </strong>
                            </p>

                            <div className="mb-2" style={{ height: opened === 1 ? 'auto' : 0, overflow: "hidden" }}>
                                <ul className="list-base">
                                    <li>A közvetítő szolgáltató által tárolt, továbbított és hozzáférhetővé tett jogellenesnek vélt tartalommal kapcsolatban.</li>
                                </ul>
                            </div>

                            <p onClick={() => switchOpened(2)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Mit tesz a közvetítő szolgáltató a bejelentést követően?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 2 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Egy pártatlan vizsgálatot folytat le a vitatott tartalommal kapcsolatban és ezzel kapcsolatban hoz döntést.
                                </li>
                            </ul>
                            <p onClick={() => switchOpened(3)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Milyen kimenetele lehet az eljárásnak?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 3 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Amennyiben a tartalom nem jogellenes és összhangban áll a felek közötti szerződéssel, úgy nem alkalmaz semmilyen szankciót.
                                </li>
                                <li>
                                    Amennyiben viszont jogellenes vagy a szerződéssel nincsen összhangban, úgy a 6. pontban meghatározott szankciókat alkalmazhatja.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(4)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Kötelező indokolni a közvetítő szolgáltató által hozott döntést?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 4 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Igen, a 6. pontban meghatározott módon.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(5)} style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                                <strong>
                                    Van-e bármilyen jogorvoslati lehetőségem a meghozott döntéssel szemben?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 5 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Igen, Önnek a döntést követően 6 hónapig van lehetősége egy belső panaszkezelési rendszeren panaszt tenni a meghozott döntéssel szemben. Emellett a panasz elbírálását követően az 9. pontban meghatározott peren kívüli vitarendezést is választhatja. Mindezek a jogorvoslati lehetőségek nem korlátozzák a perindítás lehetőségét.
                                </li>
                            </ul>

                            <p onClick={() => switchOpened(6)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                <strong>
                                    Mit tehetek, amennyiben a panaszomat a közvetítő szolgáltató pozitívan bírálta el, ám a korlátozással érintett időszakban károm keletkezett?
                                </strong>
                            </p>

                            <ul style={{ height: opened === 6 ? 'auto' : 0, overflow: "hidden" }} className="list-base">
                                <li>
                                    Ön jogosult lesz arra, hogy a magyar kártérítési szabályoknak megfelelően kártérítést kérjen minden olyan kárért vagy veszteségért, amely amiatt keletkezett, hogy a közvetítő szolgáltató nem tartotta be az említett jogszabályi kötelezettségeit.
                                </li>
                            </ul>
                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "tarhely-ugyfel-gyik" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
