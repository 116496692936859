import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

import PricingArea from "@containers/pricing/layout-01";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo
                title="Visszaélés bejelentés"
                description="A visszaélés-bejelentési lehetőség biztosításnak úgy is eleget tehet a kötelezett, hogy letölti az IT Law Solutions által fejlesztett egyedi szoftvert és megrendeli bejelentővédelmi szolgáltatásunkat."
                keywords="visszaélés-bejelentés, panaszbejelentés, panasztörvény bejelentés, bejelentővédelem"

            />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>
                            <p>
                                Szolgáltatásunk sikeres megrendelését és a szerződés megkötését követően egy egyedi kódot generálunk, amelyet megküldünk Ügyfelünknek. Ezt a kódot az Ügyfelünk által üzemeltetett weboldal forráskódjába beillesztve automatikusan, azonnal és Ügyfelünk weboldalába közvetlenül beépülő modulként elérhetővé válik a bejelentők számára.
                            </p>
                            <p>
                                <strong>Szolgáltatásunk alapvetően három részterméket foglal magában: </strong>

                            </p>
                            <p>
                                (1) a visszaélés-bejelentési rendszer üzemeltetését,
                                <br />
                                (2) a bejelentővédelmet, valamint
                                <br />
                                (3) a bejelentések kivizsgálását
                            </p>
                            <p>
                                melyek megléte a 2023. évi XXV. törvény alapján nevesített foglalkoztatók számára kötelező. Cégünknél ezen termékek külön-külön nem vásárolhatók meg, hanem minden csomagunk tartalmazza valamennyi terméket. Az a meglátásunk, hogy Ügyfeleink számára csak akkor tudunk érdemi segítséget nyújtani, ha nem csupán a belső visszaélés-bejelentési rendszer üzemeltetését és működésének biztosítását, hanem az egész folyamatot magas szakmai színvonalon támogatjuk beleértve a bejelentések kivizsgálását.

                            </p>


                            { /* <p>
                                <strong>Megrendelhető csomagok</strong>
            </p> */}
                            <PricingArea data={content["pricing-section"]} />
                            { /* <p>
                                KIRÁLY CSOMAG: A csomag havidíja: 23.900 Forint, emellett minden bejelentés beérkezése (ún. ticketek) után 4.990 Forint kezelési díjat számolunk fel.
                                <br />
                                Csomag tartalma:
                                <br />
                                Az Ügyfél weboldalába automatikusan beépülő modul
                                <br />
                                A szoftveres rendszer működésének folyamatos ellenőrzése, szükség esetén karbantartása
                                <br />
                                Bejelentővédelmi kötelezettségek teljesítése
                                <br />
                                Személyes adatok védelme
                                <br />
                                Bejelentések teljeskörű vizsgálata bejelentővédelmi ügyvéd bevonásával
                                <br />
                                A beérkezett bejelentések elszámolása ticketként
                            </p>
                            <p>
                                JOKER CSOMAG: A csomag havidíja 29.990 Forint, amely nem csupán a szoftver üzemeltetését, hanem a beérkezett bejelentések kezelési díját is tartalmazza függetlenül azok számától.
                                <br />
                                Az Ügyfél weboldalába automatikusan beépülő modul
                                <br />
                                A szoftveres rendszer működésének folyamatos ellenőrzése, szükség esetén karbantartása
                                <br />
                                Bejelentővédelmi kötelezettségek teljesítése
                                <br />
                                Személyes adatok védelme
                                <br />
                                Bejelentések teljeskörű vizsgálata bejelentővédelmi ügyvéd bevonásával
                                <br />
                                A kivizsgált bejelentések költsége a havidíjba van kalkulálva
        </p> */ }
                            <p>
                                A megadott csomagokhoz nem tartozik telepítési / regisztrációs díj, valamint a feltüntetett árak az általános forgalmi adót nem tartalmazzák.
                            </p>
                            <p>
                                A megnevezett csomagajánlatok közös vonása, hogy nem választja el egymástól a bejelentővédelmet, a belső visszaélés-bejelentési rendszer üzemeltetését, valamint a bejelentések kivizsgálását. Ez a szisztéma azért hatékony, mert a panaszkezelésbe bevont szerv nem csupán a technikai háttér megteremtésének és üzemeltetésének feladatát veszi át a vállalkozástól, hanem a folyamatba annak elejétől a végéig bevonásra kerül. Így tehát nem csupán a technikai feltételek, hanem a bejelentések magas szakmai színvonalon történő kivizsgálását is biztosítja cégünk anélkül, hogy a vállalkozásnak erre külön szervezeti egységet kellene létrehoznia, vagy mást (például bejelentővédelmi ügyvédet) kellene bevonnia a folyamatba. Emellett csomagjainkhoz nem tartozik egyszeri telepítési díj, hiszen a szoftver használata egyszerű, Ügyfelünk által is könnyen, csupán néhány kattintással a saját weboldalba integrálható automatikusan beépülő modulként működik.

                            </p>

                        </div>

                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "visszaelles-szolgaltatasunk" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
