import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import AboutArea from "@containers/about/layout-04";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo title="Bejelentek" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>
                            <p>
                                Az IT Law Solutions Kft. egy 2008-ban társaadalomtudományi kutatásra alapított vállalkozás, melyet a tagok 2023-ban átalakítottak, és amelynek fő célja olyan komplex jogi informatikai szolgáltatásokat nyújtani, amelyekkel Ügyfeleink kényelmesen tehetnek eleget új jogi kötelezettségeiknek. Ilyen jogi kötelezettségek a panaszokról, a közérdekű bejelentésekről, valamint a visszaélések bejelentésével összefüggő szabályokról szóló 2023. évi XXV. törvényben egyes vállalkozások számára kötelezővé tett feladatok vagy a jogellenes tartalmakra vonatkozó bejelentésekkel kapcsolatos eljárásrend kialakítását szabályozó  európai uniós DSA rendeletnek való megfelelés.
                            </p>
                            <p>
                                A fenti szabályok betartása az arra kötelezettek részéről folyamatos odafigyelést igényel, melyben automatizált folyamatok bevezetésével kínálunk segítségnyújtást.
                            </p>
                            <p>
                                Abban vagyunk mások, mint a versenytársaink, hogy automatikusan, könnyen és kompakt módon biztosítjuk az európai uniós és a hazai jogi környezetnek való megfelelést, anélkül, hogy Ügyfeleinknek ehhez külön belső szervezetrendszert vagy külön folyamatot kellene létrehoznia vagy működtetnie.
                            </p>
                            <p>
                                Mindezen fentiekkel egy lépésben kívánjuk levenni a terhet ügyfeleink válláról, mind a visszaélés-bejelentések, mind a jogellenes tartalmakkal kapcsolatos bejelentések terén.
                            </p>
                            <p>
                                Abban hiszünk, hogy a bejelentések rendezett becsatornázása és magas szakmai színvonalon történő kivizsgálása csak a folyamat egészében való részvétellel teremthető meg.
                            </p>
                            <p>
                                Mi az IT Law Solutions Kft-ben a jogászok és fejlesztők együttműködésében olyan megoldásokat kínálunk, melyek egyaránt figyelembe veszik a jogszabályoknak való maradéktalan megfelelést, valamint azt az ügyféligényt, hogy ez ne teher legyen, megoldandó feladat, hanem egy észrevehetetlen automatizmus. Abban különbözünk versenytársainktól, hogy a korunk elérhető legjobb technológiáját alkalmazzuk, és a folyamatot annyira automatikusan kezeljük, amennyire csak lehet.
                            </p>
                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "about-us" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
