import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-04";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { SectionWrap } from "../../containers/about/layout-01/style";
import { Container } from "react-bootstrap";

const AboutPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    const [opened, setOpened] = useState(null);

    const switchOpened = (num) => {
        if (opened === num) {
            setOpened(null)
        } else {
            setOpened(num);
        }
    }

    return (
        <Layout location={location}>
            <Seo title="Bejelentő vagyok – Bejelentői gyakori kérdések" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <SectionWrap>
                    <Container>
                        <div style={{ fontSize: '1.25rem', margin: '30px 0', textAlign: 'justify' }}>
                            <p>
                                Az alábbi kérdések a panaszbejelentői oldalon leggyakrabban felmerült témaköröket érintik:
                            </p>
                            <p onClick={() => switchOpened(1)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                1.	Mit szabályoz a Panasztörvény?
                            </p>
                            <div className="mb-2" style={{ height: opened === 1 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A panaszokról, a közérdekű bejelentésekről, valamint a visszaélések bejelentésével összefüggő szabályokról szóló 2023. évi XXV. törvény (röviden: Panasztörvény) célja, hogy bármely jogsértés vagy érdeksérelem esetén Ön bejelentést tudjon tenni foglalkoztatójával, vagy olyan szervvel / személlyel szemben, akivel Ön munkavégzésre irányuló szerződéses kapcsolatban áll.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(2)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                2.	Kire / kikre vonatkozik ez a szabályozás?
                            </p>
                            <div className="mb-2" style={{ height: opened === 2 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A törvény az egyes állami és önkormányzati szervek mellett az olyan foglalkoztatók számára teszi kötelezővé a panaszbejelentőrendszer működtetését, akik legalább 50 főt foglalkoztatnak.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(3)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                3.	Mi az a bejelentés?
                            </p>
                            <div className="mb-2" style={{ height: opened === 3 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentés valamilyen bármilyen jogellenes, jogellenesnek vélt cselekmény, mulasztás, visszaélés vagy Önt ért érdeksérelem bejelentését jelenti. Ilyen körülményeket észlelhet például munkavégzése közben, a munkavégzés befejeztével, valamint akár informális események során is.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(4)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                4.	Ki lehet bejelentő?
                            </p>
                            <div className="mb-2" style={{ height: opened === 4 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Ön akkor tehet bejelentést, ha az adott foglalkoztatónál dolgozik, nála dolgozott, vagy a foglalkoztatónál kíván dolgozni és ennek érdekében felvételi eljárása van folyamatban. Emellett akkor is tehet bejelentést, ha a foglalkoztatóval valamilyen egyéb kapcsolatban áll például annak beszállítója, alvállalkozója, gyakornoka. Tehát minden esetben szükséges, hogy valamilyen foglalkoztatásra irányuló jogviszony álljon Ön és foglalkoztatója között.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(5)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                5.	Ki minősül rosszhiszemű bejelentőnek? Milyen következményei vannak a valótlan, hamis bejelentésnek?
                            </p>
                            <div className="mb-2" style={{ height: opened === 5 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Amennyiben kiderül, hogy Ön valótlan, hamis információkat szolgáltatott a bejelentés során, abban az esetben a bejelentés kivizsgálása mellőzhető és az ügy befejezhető. Abban az esetben, ha a bejelentett valótlan információk bűncselekmény vagy szabálysértést valósítanak meg, valamint másnak jogellenesen kárt okoznak, a kivizsgálásra jogosult szerv / személy intézkedik a szükséges eljárás megindításáról.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(6)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                6.	Milyen módon tehető bejelentés?
                            </p>
                            <div className="mb-2" style={{ height: opened === 6 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Bejelentés írásban vagy szóban tehető. Legtöbb esetben ez e-mail formájában vagy pedig belső visszaélés-bejelentési rendszeren keresztül lehetséges. Cégünk egy olyan belső visszaélés-bejelentési szolgáltat vállalkozásoknak, amely, mint egy weboldalba beépülő modul közvetlenül és egyszerűen elérhető a bejelentők számára.  Amennyiben az Ön foglalkoztatója a partnerünk, ide kattintva könnyen elérheti foglalkoztatójának a panaszbejelentésre szolgáló oldalát. A foglalkoztatójának weboldalán megtalálható visszaélés-bejelentési rendszeren túl lehetősége van továbbá telefonon keresztül is bejelentést tenni az alábbi telefonszámon: +3619988360
                                </p>
                            </div>
                            <p onClick={() => switchOpened(7)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                7.	Mikor tehető bejelentés? Milyen határidőket kell a bejelentőnek betartania?
                            </p>
                            <div className="mb-2" style={{ height: opened === 7 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentés előterjesztésére a törvény szigorú határidőt szab. Amennyiben Ön észlel / tudomására jut olyan körülmény, amely megalapozza a bejelentést, a tudomásra jutástól számított 6 hónapja van annak megtételére. A körülmény tényleges bekövetkezésétől számított 1 éven túl viszont nincsen lehetőség a bejelentés kivizsgálására.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(8)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                8.	Név nélkül is tehető bejelentés? Miben különbözik az eljárás a név nélküli bejelentés és a névvel ellátott bejelentés esetén?
                            </p>
                            <div className="mb-2" style={{ height: opened === 8 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentéseket névvel ellátva vagy név nélkül is be lehet nyújtani. Fontos azonban hangsúlyozni, hogy a törvény alapján a név nélkül tett bejelentések kivizsgálása mellőzhető. Ez alól csak az képez kivételt, ha a bejelentés valamilyen súlyos jogsértésre vagy érdeksérelemre vonatkozik. Amennyiben Ön a bejelentés során személyes adatait is megadja – mely által Önnel kapcsolatot tudunk tartani – abban az esetben lényegesen csökken azon esetek száma, amikor mellőzni szükséges a bejelentés kivizsgálását. A kivizsgálás ugyanis ebben az esetben csak a törvényben meghatározott feltételek esetén mellőzhető, például ugyanazon bejelentő által, ugyanazon jogsértéssel kapcsolatos bejelentés, vagy határidőn túl beérkező bejelentés esetén.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(9)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                9.	Mi történik a bejelentő által tett bejelentéssel?
                            </p>
                            <div className="mb-2" style={{ height: opened === 9 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Amennyiben Ön bejelentést tett, annak beérkezéséről a benyújtást követő 7 napon belül tájékoztatást fog kapni. A vizsgálatot egy olyan szerv / személy folytatja le, aki az ügyben teljesen pártatlan. A vizsgálat során feltárnak minden olyan körülményt, amely összefüggésben áll az Ön által tett bejelentéssel és ezek eredményétől függően kerül lezárásra az ügy.
                                </p>
                                <p>
                                    Versenytársainktól eltérően cégünk nem csupán a belső visszaélés-bejelentési rendszer üzemeltetését, valamint a bejelentések becsatornázását végzi, hanem ezek mellett ellátja a bejelentővédelmi kötelezettségeket, valamint a bejelentések kivizsgálását. A vizsgálat lefolytatása egy ügyvédi iroda bevonásával történik, ahol magas szakmai színvonalon tesznek eleget a bejelentett körülmények kivizsgálásának. A vizsgálat lefolytatását követően az ügyet lezárjuk, és annak kimeneteléről mind Önt - mint bejelentőt – mind pedig partnerünket tájékoztatjuk.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(10)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                10.	Mennyi ideig tart a bejelentés kivizsgálása?
                            </p>
                            <div className="mb-2" style={{ height: opened === 10 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentést a lehető leggyorsabban, de legkésőbb annak beérkezésétől számított 30 napon belül ki kell vizsgálni. Amennyiben a vizsgálat lefolytatása előreláthatóan ennél hosszabb időt vesz igénybe, a vizsgálatot folytató szerv / személy köteles annak várható idejéről Önt, mint bejelentőt írásban tájékoztatni. A vizsgálat véghatárideje a beérkezéstől számított 3 hónap lehet.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(11)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                11.	Ki vizsgálhatja ki a bejelentett körülményeket?
                            </p>
                            <div className="mb-2" style={{ height: opened === 11 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentést a foglalkoztató szervezetrendszerén belül egy független és pártatlan személy vizsgálhatja ki, emellett megbízható ennek elvégzésével egy külső szerv vagy bejelentővédelmi ügyvéd is. Ezen külső szereplőkre a törvény további összeférhetetlenségi feltételeket állapít meg.
                                </p>
                                <p>
                                    Cégünk arra szerződik vállalkozásokkal, hogy mint külső szereplő teljesítse a belső visszaélés-bejelentési rendszer üzemeltetésével, a bejelentővédelemmel, valamint a bejelentések kivizsgálásával kapcsolatos kötelezettségeket. Amennyiben az Ön foglalkoztatója a mi partnerünk, úgy az Ön által tett bejelentést cégünk fogadja, és rajtunk keresztül egy ügyvédi iroda – mint bejelentővédelmi ügyvéd – vizsgálja ki azt.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(12)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                12.	Mit jelent a bejelentővédelem?
                            </p>
                            <div className="mb-2" style={{ height: opened === 12 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentő számára a törvény védelmet biztosít, így védelem illeti meg Önt, mint bejelentőt, ha az információ / körülmény a törvény mellékleteiben meghatározott európai uniós szabályok, vagy azokkal összefüggő jogszabályok hatálya alá tartozik. Ilyennek tekinthetők a pénzügyi szolgáltatások, a közbeszerzési eljárások, közegészségügyi szolgáltatások, fogyasztóvédelmi és élelmiszerbiztonsági eljárások. Abban az esetben is megilleti Önt a védelem, hogyha alapos okkal feltételezte, hogy az információ ilyen szabályok hatálya alá tartozik.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(13)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                13.	Milyen segítségben / támogatásban részesülhet a bejelentő?
                            </p>
                            <div className="mb-2" style={{ height: opened === 13 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A törvény alapján Önt, mint bejelentőt megilletik a jogi segítségnyújtásról szóló 2003. évi LXXX. törvényben meghatározott támogatások, amennyiben az azokhoz kapcsolódó feltételek fennállnak. Így megilleti Önt:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>a jogi segítő igénybevétele, melyet az állam előlegez meg és esetenként átvállalja annak költségeit,</li>
                                        <li>jogi szolgáltatások nyújtása a jogi segítő által, </li>
                                        <li>per esetében pártfogó ügyvéd által nyújtott segítség, </li>
                                        <li>vádemelés esetén védő kirendelésére. </li>

                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(14)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                14.	Mikor nem részesül a bejelentő védelemben?
                            </p>
                            <div className="mb-2" style={{ height: opened === 14 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A védelem abban az esetben nem illeti meg Önt, ha:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>a bejelentéssel összefüggésben a minősített adatok védelmére vonatkozó szabályokat megszegi,</li>
                                        <li>orvosi titokra, ügyvédi titokra vonatkozó szabályokat megszegi, </li>
                                        <li>egyházi személy vagy vallási vezetővel kapcsolatos titoktartási kötelezettségének nem tesz eleget,</li>
                                        <li>bírói ítélkezéssel kapcsolatban törvény által védett titok védelmére vonatkozó szabályokat megszegi,</li>
                                        <li>egyes rendvédelmi szervek, a Katonai Nemzetbiztonsági Szolgálat, valamint a Nemzeti Adó- és Vámhivatal állományának tagjaként ezen szervekre irányadó szabályokat megszegi.</li>
                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(15)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                15.	Milyen eredménye lehet bejelentés kivizsgálásának?
                            </p>
                            <div className="mb-2" style={{ height: opened === 15 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    A bejelentés kivizsgálásának alapvetően három kimenetele lehet:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>a bejelentés hamis vagy valótlan információkon alapult, vagy határidőn túl került benyújtásra, mely esetekben a vizsgálat lefolytatását mellőzi,</li>
                                        <li>a bejelentés nem bizonyult alaposnak, ezért a vizsgálatot megszünteti, </li>
                                        <li>a bejelentés alaposnak bizonyul, ezért gondoskodik a törvényben meghatározottak teljesítéséről. </li>
                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(16)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                16.	Mi történik, ha a bejelentés alapos?
                            </p>
                            <div className="mb-2" style={{ height: opened === 16 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Amennyiben a bejelentés alapos volt, a vizsgálatot lefolytató szerv / személy gondoskodik:
                                </p>
                                <p>
                                    <ul style={{ listStyle: 'outside' }}>
                                        <li>a jogszerű állapot helyreállításáról, illetve az adott esetben szükséges intézkedések megtételéről,</li>
                                        <li>a feltárt hibák okainak megszüntetéséről, </li>
                                        <li>okozott sérelem orvoslásáról, </li>
                                        <li>indokolt esetben a felelősségre vonás megkezdéséről (esetlegesen más eljárás megindításáról).</li>
                                    </ul>
                                </p>
                            </div>
                            <p onClick={() => switchOpened(17)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                17.	Érheti-e a bejelentőt vagy rá tekintettel mást (például hozzátartozót, munkatársat) hátrány a bejelentése miatt?
                            </p>
                            <div className="mb-2" style={{ height: opened === 17 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Önt, mint bejelentőt, valamint Önre nézve mást főszabály szerint hátrány nem érheti a bejelentés megtétele miatt. Ez alól kivétel, amennyiben hamis vagy valótlan információt közöl és emiatt bűncselekmény vagy szabálysértés elkövetésére utaló körülmények merülnek fel, valamint, ha a valótlan bejelentéssel másnak jogellenesen kárt okoz. Ezekben az esetekben a vizsgálatot lefolytató személy kezdeményezi a szükséges eljárás lefolytatását.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(18)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                18.	Ki jogosult a bejelentő adatainak megismerésére?
                            </p>
                            <div className="mb-2" style={{ height: opened === 18 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Amennyiben Ön bejelentést tett, úgy személyes adatainak kezelése zártan történik. Azok megismerésére csak a vizsgálatot lefolytató szerv / személy válik jogosulttá, minden egyéb esetben adatai csak az Ön hozzájárulásával hozhatók nyilvánosságra. A cégünk által végzett adatkezeléssel összefüggésben bővebb tájékoztatót az Adatvédelmi szabályzat fül alatt talál.
                                </p>
                            </div>
                            <p onClick={() => switchOpened(19)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                                19.	Honnan kap információt a bejelentő az eset kivizsgálásának állásáról?
                            </p>
                            <div className="mb-2" style={{ height: opened === 19 ? 'auto' : 0, overflow: "hidden" }}>
                                <p>
                                    Miután Ön bejelentést tett, az eljárást lefolytató szerv / személy köteles legkésőbb 7 napon belül egy visszaigazolást küldeni a bejelentés beérkezéséről. Ezt követően az eljárás során az eljáró szerv / személy Önnel folyamatosan kapcsolatot tart, így írásban tájékoztatja Önt többek között arról, ha a vizsgálat hosszabb ideig tart, mint a törvényi 30 nap, amennyiben a vizsgálat lefolytatását mellőzik, valamint magáról a vizsgálat eredményéről is.
                                </p>
                            </div>
                        </div>
                    </Container>
                </SectionWrap>
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AboutPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "bejelento-gyik" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

AboutPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default AboutPage;
