import React, { useContext, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Row, Col } from "@ui/wrapper";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { FormGroup, Input, Textarea } from "@ui/form-elements";
import Button from "@ui/button";
import UserContext from "../../../context/UserContext";

const CartForm = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { user, cart, handleSetCart } = useContext(UserContext);

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });


    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://back.itlawsolutions.hu/api/subscriptions/pay",
            data: {
                ...data,
                user_id: user?.id,
                subscription_type: cart?.product
            },
            headers: {
                Authorization: `Bearer ${user?.access_token}`
            }
        })
            .then((r) => {
                handleSetCart({ product: "SUCCESS" });
            })
            .catch((r) => {
                console.log('err')
            });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={12}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="name"
                            placeholder="Webhely neve *"
                            feedbackText={errors?.name?.message}
                            state={
                                hasKey(errors, "name") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "name")}
                            {...register("name", {
                                required: "Webhely megadása kötelező"
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="url"
                            placeholder="Webhely címe *"
                            feedbackText={errors?.url?.message}
                            state={
                                hasKey(errors, "url") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "url")}
                            {...register("url", {
                                required: "Webhely címének megadása kötelező"
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="invoice_name"
                            placeholder="Számlázási név *"
                            feedbackText={errors?.invoice_name?.message}
                            state={
                                hasKey(errors, "invoice_name") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "invoice_name")}
                            {...register("invoice_name", {
                                required: "Számlázási név megadása kötelező"
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="invoice_tax_nm"
                            placeholder="Adószám *"
                            feedbackText={errors?.invoice_tax_nm?.message}
                            state={
                                hasKey(errors, "invoice_tax_nm") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "invoice_tax_nm")}
                            {...register("invoice_tax_nm", {
                                required: "Adószám megadása kötelező"
                            })}
                        />
                    </FormGroup>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="invoice_address"
                            placeholder="Számlázási cím *"
                            feedbackText={errors?.invoice_address?.message}
                            state={
                                hasKey(errors, "invoice_address") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "invoice_address")}
                            {...register("invoice_address", {
                                required: "Webhely címének megadása kötelező"
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>

                    <p className="pb-3">
                        A vásárlás jelenleg csak utalással lehetséges. A megvásárlás után e-mailben értesítjük az utaláshoz szükséges banki adatokról.
                    </p>

                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Button type="submit" disabled={serverState.submitting}>
                        Csomag megvásárlása
                    </Button>
                    {serverState.status && (
                        <p
                            className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"
                                }`}
                        >
                            {serverState.status.msg}
                        </p>
                    )}
                </Col>
            </Row>
        </form>
    );
};

export default CartForm;
