import React, { useContext, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Row, Col } from "@ui/wrapper";
import { useFormUrl } from "@hooks";
import { hasKey } from "@utils";
import { FormGroup, Input, Textarea } from "@ui/form-elements";
import Button from "@ui/button";
import UserContext from "../../../context/UserContext";
import { StyledNavlink } from "../../menu/main-menu/style";

const RegisterForm = () => {

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const { login } = useContext(UserContext);

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    });


    const onSubmit = (data, e) => {
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://back.itlawsolutions.hu/api/auth/register",
            data: data,
        })
            .then((r) => {
                login(r?.data?.authorisation?.token);
            })
            .catch((r) => {
                setServerState({ submitting: false, status: { ok: true, msg: r?.response?.data?.errors } })
                console.log('err', r?.response?.data?.errors)
            });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutters={{ lg: 20 }}>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="text"
                            id="name"
                            placeholder="Név *"
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "name") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "name")}
                            {...register("name", {
                                required: "Név kötelező."
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="email"
                            id="email"
                            placeholder="Email *"
                            feedbackText={errors?.email?.message}
                            state={
                                hasKey(errors, "email") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "email")}
                            {...register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address",
                                },
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="password"
                            id="password"
                            placeholder="Jelszó *"
                            feedbackText={errors?.password?.message}
                            state={
                                hasKey(errors, "password") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "password")}
                            {...register("password", {
                                required: "Jelszó megadása kötelező.",
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup mb="20px">
                        <Input
                            type="password"
                            id="password_confirmation"
                            placeholder="Jelszó mégegyszer *"
                            feedbackText={errors?.password_confirmation?.message}
                            state={
                                hasKey(errors, "password_confirmation") ? "error" : "success"
                            }
                            showState={!!hasKey(errors, "password_confirmation")}
                            {...register("password_confirmation", {
                                required: "Jelszó megadása kötelező.",
                                validate: (val) => {
                                    if (watch('password') != val) {
                                        return "A jelszavak nem egyeznek.";
                                    }
                                },
                            })}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Button type="submit" disabled={serverState.submitting}>
                        Regisztráció
                    </Button>
                    <StyledNavlink
                        className="nav-link"
                        path="/login"
                        vSpace={2}
                        space={2}
                        color="dark"
                        bottomLine={true}
                    >
                        <span>Már van fiókom, bejelentkezek.</span>
                    </StyledNavlink>
                    {serverState.status && (
                        <p
                            className={`form-output ${!serverState?.status?.ok ? "errorMsg" : "success"
                                }`}
                        >
                            {serverState?.status?.msg?.email?.map(m => <>
                                {m} <br />
                            </>)}
                            {serverState?.status?.msg?.password?.map(m => <>
                                {m} <br />
                            </>)}
                            {serverState?.status?.msg?.name?.map(m => <>
                                {m} <br />
                            </>)}
                        </p>
                    )}
                </Col>
            </Row>
        </form>
    );
};

export default RegisterForm;
